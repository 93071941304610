import logo from './logo.svg';
import './App.css';
import PrivacyPolicyScreen from './screens/Privacy';

function App() {
  return (
<PrivacyPolicyScreen/>
  );
}

export default App;
